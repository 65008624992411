import {
  DateTimeType,
  FinancialValueType,
  FinancialConfigType,
  FinancialDataItemType,
  FinancialStatementDataType
} from '#/FinancialsTypes'
import {
  get,
  find,
  keys,
  every,
  filter,
  keyBy,
  remove,
  uniqBy,
  isEmpty,
  toLower,
  orderBy,
  groupBy,
  indexOf,
  replace,
  forEach,
  toUpper,
  findIndex,
  includes,
  cloneDeep
} from 'lodash-es'
import dayjs from 'dayjs'
import ReportsApi from '@/api/ReportsApi'
import LedgerApi from '@/api/LedgerApi'
import { useEntityStore } from '../entity'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { type GridApi } from '@ag-grid-community/core'
import { codesConfigMap, extraMap, codesMap, mockBS, mockIS, mockCFS, mockRF, mockRCA } from '@/config/reports/show'
import { FinancialDataListType, AccountMappingItemType } from '#/ReportsTypes'

dayjs.extend(isSameOrAfter)
dayjs.extend(quarterOfYear)
export const useReportStore = defineStore({
  id: 'reportStore ',
  state: () => {
    const reportState: {
      financialFieldConfig: FinancialConfigType[]
      financialData: FinancialDataListType
      loading: boolean
      agGridApi: GridApi | null
      sourceDoingCount: number
      sourceSyncList: any[]
      regenerateReportList: any[]
      guidanceUnDisplayList: string[]
      accountMapping: AccountMappingItemType
      reportConfig: any
      reportPeriod: string
      reportPeriodDateOperateList: any[]
      allCodes: string[]
      auxiliaryTypeList: any[]
    } = {
      reportConfig: {},
      financialFieldConfig: [],
      financialData: {
        updatedOn: '',
        list: [],
        errorList: []
      },
      agGridApi: null,
      loading: true,
      guidanceUnDisplayList: [],
      sourceDoingCount: 0,
      sourceSyncList: [],
      regenerateReportList: [],
      reportPeriodDateOperateList: [],
      accountMapping: {
        system: [],
        integration: []
      },
      reportPeriod: 'MONTHLY',
      allCodes: [],
      auxiliaryTypeList: []
    }
    return reportState
  },
  actions: {
    async getAllAuxiliaryTypeList(entityId: string) {
      const res = await LedgerApi.getAuxiliaryTypeList(entityId, {
        page: 1,
        limit: 100
      })
      this.auxiliaryTypeList = res.data.list || []
    },
    /**
     * 获取table-field配置
     * @param {string} type
     * @returns
     */
    async fetchTableFieldList(type: string) {
      const entityStore = useEntityStore()
      const journalTypeMap = keyBy(entityStore.journalTypeList, 'code')
      const chartOfAccountMap = keyBy(entityStore.chartOfAccountList, 'code')
      const currencyMap = keyBy(entityStore.currencyList, 'symbol')

      // 找出所有的codes ['100-00', '100-01', '100-agg']
      let allFieldValues: any = []
      this.financialData.list.forEach((item: any) => {
        allFieldValues.push(...item.fieldValues)
      })
      allFieldValues = uniqBy(allFieldValues, 'fieldId').sort()
      this.allCodes = allFieldValues.map((fieldValue: any) => fieldValue.fieldId)
      const parentCodes = codesMap[type]
      const codeConfigMap: any = codesConfigMap[type]
      const result: any[] = []
      try {
        if (
          ['balance-sheet', 'income-statement', 'cash-flow-statement', 'roll-forward', 'restrictions'].includes(type)
        ) {
          forEach(parentCodes, (parentCode: string) => {
            const config = codeConfigMap[parentCode]
            result.push({
              code: parentCode,
              fieldId: parentCode,
              showType: config.showType,
              name: config.name || '',
              extra: config.extra,
              parentId: '0'
            })

            const parentCodeLength = parentCode.split('-').length
            let childCodes: Array<any> = []
            if (config.hasCurrency) {
              childCodes = filter(this.allCodes, (code: string) => {
                if (includes(parentCodes, code)) return false
                const parts = code.split('-')
                const secondHyphenIndex = parts[0].length + parts[1].length + 1
                const lastIndex = code.lastIndexOf('-')
                const firstIndex = ['balance-sheet', 'income-statement'].includes(type)
                  ? code.indexOf('-')
                  : code.indexOf('-', secondHyphenIndex)
                const suffix = lastIndex !== -1 ? code.substring(0, lastIndex) : code
                const prefix = firstIndex !== -1 ? code.substring(0, firstIndex) : code
                const parentPrefix = parentCode.substring(0, firstIndex) || parentCode
                if (
                  code.indexOf(`${parentCode}-`) === -1 ||
                  suffix.indexOf(parentCode) === -1 ||
                  parentPrefix !== prefix
                )
                  return false
                const codeLength = code.split('-').length
                if (
                  (code.indexOf('general') > -1 || code.indexOf('exchange-rate') > -1) &&
                  codeLength - parentCodeLength !== 1
                ) {
                  return false
                }

                if (code.indexOf('group') > -1) {
                  const groupPrefixCode = code.split('-group')[0]
                  if (groupPrefixCode !== parentCode && includes(parentCodes, groupPrefixCode)) {
                    return false
                  }
                }

                return true
              })
            }
            if (childCodes.length === 0) return
            // 100
            // 100-01 100-01-usd
            // 100-general-journal 100-general-journal-usd
            // cashflow-100-10000-gst
            // 将group分组的子节点重新排序到所属父级节点后边
            childCodes = childCodes.sort((a, b) => {
              const [aBase, aGroup] = a.split('-group')
              // eslint-disable-next-line no-unused-vars
              const [bBase, bGroup] = b.split('-group')
              if (aBase === bBase) return aGroup ? 1 : -1
              return aBase.localeCompare(bBase)
            })
            forEach(childCodes, (childCode: string, childIndex: number) => {
              let { currencyPosition } = config
              if (childCode.indexOf('10000-gst') > -1) {
                currencyPosition += 1
                journalTypeMap.GST = journalTypeMap['10000-gst']
              }
              const itemFieldValue = find(allFieldValues, { fieldId: childCode })
              const isGroup = itemFieldValue?.isGroup
              const isSubGroup = itemFieldValue?.isSubGroup
              const currencyData = itemFieldValue?.underlyingCurrency
              const lastCode = toUpper(childCode.split('-')[currencyPosition - 1])
              const lastLetterLower = currencyData?.symbol
                ? toLower(currencyData?.symbol)
                : childCode.split('-')[currencyPosition]
              const lastLetter = currencyData?.symbol || toUpper(lastLetterLower)
              const calParentCode = replace(childCode, `-${lastLetterLower}`, '')

              // 100-00这种
              if (config.hasChildren && !lastLetter) {
                result.push({
                  code: childCode,
                  fieldId: childCode,
                  showType: 'NORMAL',
                  name:
                    (config.codeDefine === 'JOURNAL_TYPE'
                      ? journalTypeMap[lastCode]?.name
                      : chartOfAccountMap[childCode]?.name) || '',
                  extra: extraMap.NORMAL,
                  parentId: parentCode
                })
                return
              }

              if (isGroup || isSubGroup) {
                const { name, value } = this.getItemNameAndValue(itemFieldValue)
                const backCodes = childCodes.slice(childIndex + 1)
                const isHasChild = backCodes.some((code) => code.includes(childCode) && code !== childCode)
                result.push({
                  ...itemFieldValue,
                  code: itemFieldValue.fieldId,
                  showType: 'NORMAL',
                  name,
                  value,
                  extra: isGroup ? { ...extraMap.CHILDREN, whetherExpanded: isHasChild } : extraMap.CHILDREN
                })
                return
              }

              // 100-00-usd这种
              result.push({
                ...(isEmpty(currencyData) ? currencyMap[lastLetter] : currencyData || {}),
                underlyingCurrency: isEmpty(currencyData) ? currencyMap[lastLetter] : currencyData || {},
                code: childCode,
                fieldId: childCode,
                showType: 'CHILDREN',
                extra: extraMap.CHILDREN,
                parentId: calParentCode
              })
            })
          })
        }
        this.financialFieldConfig = result
        return true
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 财务报表数据初始化
     * @param {string} entityId
     * @param {string} type 报表类型 (balance-sheet/income-statement/cash-flow-statement)
     * @param {Object} params
     * @property {string} period 期间 // MONTHLY/WEEK/DAY
     * @property {string[]} dateRange 日期范围 // [2021-01-01,2021-01-31]
     * @returns
     */
    async financialsDataInit(entityId: string, type: string, params: any) {
      try {
        this.loading = true
        this.financialData = {
          updatedOn: '',
          list: [],
          errorList: []
        }
        this.financialFieldConfig = []
        let response: any = {}
        this.reportPeriod = params?.period
        if (params?.period === 'YEAR') {
          // eslint-disable-next-line no-param-reassign
          delete params.dateRange
        }
        switch (type) {
          case 'balance-sheet':
            response = mockBS
            response = await ReportsApi.getBalanceSheet(entityId, params)
            break
          case 'income-statement':
            response = mockIS
            response = await ReportsApi.getIncomeStatement(entityId, params)
            break
          case 'restrictions':
            response = mockRCA
            response = await ReportsApi.getRestrictionsOfCryptoAssets(entityId, params)
            break
          case 'roll-forward':
            response = mockRF
            response = await ReportsApi.getCryptoAssetsRollForward(entityId, params)
            break
          case 'cash-flow-statement':
            response = mockCFS
            response = await ReportsApi.getCashFlowStatement(entityId, params)
            break
          default:
            break
        }
        // console.log('financialData', response.data)
        this.financialData = response.data
        await this.fetchTableFieldList(type)
        this.loading = false
        return true
      } catch (error) {
        this.loading = false
        return Promise.reject(error)
      }
    },
    /**
     * 财务报表数据
     * @param {number[]} DataTimeList 时间段id
     * @returns {FinancialStatementDataType[]}
     */
    getFinancialStatementData(DataTimeList: string[] = []): FinancialStatementDataType[] {
      // 是否筛选时间段
      const financialData = cloneDeep(this.financialData.list)
      const data = DataTimeList.length
        ? filter(financialData, (item: FinancialDataItemType) => {
            return indexOf(DataTimeList, item.id) !== -1
          })
        : financialData
      const financialStatementData: any = cloneDeep(this.financialFieldConfig)
      const valueMaps = new Map()
      // 增加判断
      let fieldValuesData: any = data[0]?.fieldValues
      if (isEmpty(fieldValuesData)) {
        fieldValuesData = data[1]?.fieldValues
      }

      this.allCodes?.forEach((code: string) => {
        const values: FinancialValueType[] = []
        data.forEach((configItem: FinancialDataItemType) => {
          const value: FinancialValueType = {
            fieldId: '',
            value: 0
          }
          const currentFieldValue: any = find(configItem.fieldValues, { fieldId: code })
          if (!isEmpty(currentFieldValue)) {
            value.fieldId = String(currentFieldValue.fieldId)
            value.value = currentFieldValue.value
          } else {
            value.fieldId = code
            value.value = 0
          }

          if (configItem?.date) {
            value.date = configItem.date
          } else {
            value.title = configItem.title
            value.fromDate = configItem.fromDate
            value.toDate = configItem.toDate
          }
          values.push(value)
        })
        valueMaps.set(code, values)
      })
      financialStatementData.forEach((item: FinancialStatementDataType) => {
        const values = valueMaps.get(item.code)
        if (values) {
          item.fieldValues = values
          if (item?.showType === 'NORMAL') {
            item.expanded = false
          }
        } else if (item?.showType === 'NORMAL' && !values) {
          item.fieldValues = this.dateTimeList.map(() => {
            return {
              fieldId: item.fieldId,
              value: ''
            }
          })
          item.expanded = false
        } else if (item?.showType === 'AGG' && !values) {
          item.fieldValues = this.dateTimeList.map(() => {
            return {
              fieldId: item.fieldId,
              value: ''
            }
          })
          item.expanded = false
        }
      })
      // 添加线的类型 lineType(1: 细线，2：粗线)
      for (let i = 0; i < financialStatementData.length; i += 1) {
        if (financialStatementData[i].showType !== 'PLACEHOLDER') {
          if (financialStatementData[i].showType === 'AGG' && !financialStatementData[i].extra?.hideBottomLine) {
            financialStatementData[i].lineType = 2
          } else if (
            financialStatementData[i + 1]?.showType &&
            financialStatementData[i + 1].showType === 'AGG' &&
            !financialStatementData[i + 1].extra?.hideTopLine
          ) {
            financialStatementData[i].lineType = 2
          } else {
            financialStatementData[i].lineType = 1
          }
        }
      }

      // 过滤出来所有3级的数据
      const level3List = filter(financialStatementData, (item: FinancialStatementDataType) => {
        return item?.showType === 'CHILDREN'
      })
      remove(financialStatementData, (item: FinancialStatementDataType) => {
        return item?.showType === 'CHILDREN'
      })
      const level3GroupList = groupBy(level3List, 'parentId')
      forEach(keys(level3GroupList), (key: string) => {
        const items = get(level3GroupList, key)
        const newThirdSortData = orderBy(items, (o: any) => Math.abs(o?.fieldValues?.[0]?.value), 'desc')
        const fieldIndex = findIndex(financialStatementData, ['fieldId', key])
        financialStatementData.splice(fieldIndex + 1, 0, ...newThirdSortData)
      })

      // 删除没有值的数据
      remove(financialStatementData, (item: FinancialStatementDataType) => {
        const isEvery = every(item.fieldValues, (fieldValue: FinancialValueType) => {
          return fieldValue.value === 0 || fieldValue.value === ''
        })
        if (
          ((item?.showType === 'NORMAL' || item?.showType === 'AGG') && !item?.extra?.showForNull) ||
          item?.showType === 'CHILDREN'
        ) {
          return isEvery
        }
        if (item?.showType === 'PLACEHOLDER' && !item?.extra?.showForNull) {
          const childrenData = filter(financialStatementData, (i: FinancialStatementDataType) => {
            return i.code.indexOf(`${item.code}-`) !== -1
          })
          const isEmptyChildren = every(childrenData, (i: FinancialStatementDataType) => {
            return every(i.fieldValues, (fieldValue: FinancialValueType) => {
              return fieldValue.value === 0 || fieldValue.value === ''
            })
          })
          return isEmptyChildren
        }
        return false
      })
      return financialStatementData
    },
    /**
     * 获取Holdings列表
     * @param {string} entityId
     * @param {Object} params
     * @property {string} date 日期范围 // 2021-01-01
     * @returns
     */
    async fetchHoldingsDataList(entityId: string, params: object = {}) {
      try {
        const { data } = await ReportsApi.getSignificantHoldings(entityId, params)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * @description: 设置Ag-Grid的Api
     * @param {GridApi} api
     */
    setAgGridApi(api: GridApi) {
      this.agGridApi = api
    },
    /**
     * 编辑交易同步状态
     * @param {string} entityId 项目id
     * @param {boolean} status  交易同步状态
     * @param {Object} data 交易同步状态数据
     */
    editSourceSync(entityId: string, status: boolean, data: any, syncTotal: number = 0) {
      const isInSourceSyncList = this.sourceSyncList.find((e: any) => {
        return e.entityId === entityId
      })
      if (isInSourceSyncList) {
        if (this.sourceDoingCount === 0 && status) {
          this.sourceSyncList.splice(
            this.sourceSyncList.findIndex((item: any) => item.entityId === entityId),
            1,
            { entityId, status, syncTotal: syncTotal ?? null, list: syncTotal ? [...isInSourceSyncList.list] : [data] }
          )
        } else {
          this.sourceSyncList.splice(
            this.sourceSyncList.findIndex((item: any) => item.entityId === entityId),
            1,
            {
              entityId,
              status,
              syncTotal: syncTotal ?? null,
              list: status ? [...isInSourceSyncList.list, data] : [...isInSourceSyncList.list]
            }
          )
        }
      } else {
        this.sourceSyncList.push({ entityId, status, list: [data] })
      }
    },
    /**
     * 修改报表生成状态
     * @param {string} entityId 项目id
     * @param {boolean} status 状态
     * @param {boolean} deleted 是否删除报表生成状态
     * @param {string} type 报表类型
     */
    editRegenerateReport(entityId: string, status: boolean, deleted: boolean = false, type: string = '') {
      const isInRegenerateReportList = this.regenerateReportList.find((e: any) => {
        return e.entityId === entityId
      })
      if (isInRegenerateReportList) {
        const regenerateIndex = this.regenerateReportList.findIndex((item: any) => item.entityId === entityId)
        let data = { entityId, status }
        if (deleted) {
          data = { ...isInRegenerateReportList, entityId, status: true, [type]: false }
        }
        this.regenerateReportList.splice(regenerateIndex, 1, data)
      } else if (!deleted) {
        this.regenerateReportList.push({ entityId, status })
      }
    },
    /**
     * 编辑报表日期操作记录
     * @param {number} entityId 主体id
     * @param {Object} data
     * @param {string} type 类型 HOLDINGS / OTHER
     */
    editReportPeriodDateOperatingRecord(entityId: string, data: Object, type: string = 'OTHER') {
      const currentIndex = this.reportPeriodDateOperateList.findIndex((item: any) => item.entityId === entityId)

      if (currentIndex !== -1) {
        if (isEmpty(data)) {
          this.reportPeriodDateOperateList.splice(currentIndex, 1)
        } else {
          this.reportPeriodDateOperateList.splice(currentIndex, 1, {
            entityId,
            other: type === 'OTHER' ? data : this.reportPeriodDateOperateList[currentIndex].other,
            holdings: type === 'HOLDINGS' ? data : this.reportPeriodDateOperateList[currentIndex].holdings
          })
        }
      } else if (!isEmpty(data)) {
        this.reportPeriodDateOperateList.push({
          entityId,
          other: type === 'OTHER' ? data : {},
          holdings: type === 'HOLDINGS' ? data : {}
        })
      }
    },
    /**
     * 获取科目映射列表
     * @param {string} entityId 主体id
     */
    async fetchAccountMappingList(entityId: string) {
      try {
        const response = await ReportsApi.getAccountMappingList(entityId)
        this.accountMapping = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    getItemNameAndValue(item: any) {
      const name = item.isOther
        ? 'Uncategorized'
        : item.entityAccount?.name || item.counterparty?.name || item.auxiliaryItem?.value || ''
      return {
        name,
        value: item.value
      }
    }
  },
  getters: {
    // 返回所有时间列表
    dateTimeList() {
      const dateTimeList: DateTimeType[] = this.financialData.list.map((item: FinancialDataItemType) => {
        let dateTime: DateTimeType = {
          id: ''
        }
        if (item?.date) {
          dateTime = {
            id: item.date,
            date: item.date,
            title: item.date
          }
        } else {
          let quarterString = ''
          switch (this.reportPeriod) {
            case 'QUARTER':
              const quarter = dayjs(item.fromDate).quarter()
              const year = dayjs(item.fromDate).year()
              quarterString = `Q${quarter}/${year}`
              break
            case 'MONTH':
              quarterString = dayjs(item.fromDate).format('MM/YYYY')
              break
            case 'YEAR':
              quarterString = `${dayjs(item.fromDate).year()}`
              break
            default:
              break
          }

          dateTime =
            this.reportPeriod === 'DAY'
              ? {
                  id: item.date,
                  date: item.fromDate,
                  title: item.fromDate
                }
              : {
                  id: item.date,
                  title: quarterString,
                  fromDate: item.fromDate,
                  toDate: item.toDate
                }
        }
        return dateTime
      })
      return dateTimeList
    }
  },
  // 本地化持久储存
  persist: {
    enabled: true,
    strategies: [
      {
        // 更改默认存储，我更改为localStorage
        storage: localStorage,
        // 默认是全部进去存储 可以选择哪些进入local存储，这样就不用全部都进去存储了
        paths: ['sourceSyncList', 'regenerateReportList', 'guidanceUnDisplayList', 'reportPeriodDateOperateList']
      }
    ]
  }
})
